<template>
  <div class="inline pull-right" style="margin-right: 0px; margin-left: 5px;">
    <span
      class="pull-right customlink"
      style="padding-right: 0px"
      value="History"
      @click="isHistoryOn = true"
    >
      <Tooltip direction="top" contents="History">
        <IconSynchronizeArrowClock size="lg" />
      </Tooltip>
    </span>
    <EditHistoryTable
      v-if="isHistoryOn"
      :entityId="entityId"
      :entityType="heading"
      @closeModal="isHistoryOn = false"
    />
  </div>
</template>

<script>
import { Tooltip } from '@cimpress/react-components';
import { IconSynchronizeArrowClock } from '@cimpress-technology/react-streamline-icons/lib';
import EditHistoryTable from '../../EditHistoryTable';

export default {
  name: 'HistoryButton',
  components: {
    Tooltip,
    IconSynchronizeArrowClock,
    EditHistoryTable
  },
  props: {
    keyValue: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isHistoryOn: false
    };
  },
  computed: {
    entityId() {
      return `${this.keyValue}/${this.entity}`;
    }
  }
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
