<template>
  <div>
    <h5>
      Tags
      <Tooltip
        direction="top"
        contents="Use tags to enable quick search for related data sources"
        class="inline"
      >
        <IconInformationCircle size="md" weight="fill" class="text-primary" />
      </Tooltip>
      <button
        v-if="userManager.userData.app_metadata.canonical_id && !isEditing"
        class="btn-icon transparent"
        @click="
          isEditing = true;
          errorMessage = null;
          newTags = tags.map((t) => t.tag_name);
        "
      >
        <Tooltip direction="top" contents="Edit">
          <i class="fa fa-pencil" aria-hidden="true" />
        </Tooltip>
      </button>
    </h5>
    <!-- functionality to be added here -->
    <div v-if="processing" class="loading inline">
      <img src="spinner.svg" width="25" alt="loading" />
    </div>

    <div v-else-if="!tags.length && !isEditing" class="loading inline" style="color: grey">
      No tags on this object.
    </div>

    <span
      v-for="tag in tags"
      v-else-if="!isEditing"
      :key="tag.id"
      class="inline"
    >
      <span class="tag" style="margin: 2px">{{ tag.tag_name }} </span>
    </span>
    <div v-if="userManager.userData.app_metadata.canonical_id && isEditing" style="padding: 0;">
      <Select
        :isMulti="true"
        :isClearable="true"
        :isDisabled="processing"
        style="width: 80%;"
        class="pull-left"
        label="Select tags"
        :options="
          allTags.tag_usages.map((v) => ({
            label: v.tag_name,
            value: v.tag_name
          }))
        "
        :value="
          newTags.map((t) => ({
            label: t,
            value: t
          }))
        "
        :inputValue="newTagValue"
        @onInputChange="(e) => newTagValue = e"
        @onChange="(e) => (newTags = (e || []).map((t) => t.value))"
      />
      <div class="inline-edit-group pull-right" style="padding: 0; margin: 0; min-width: 20%; width: 20%;">
        <div class="right-addon">
          <button title="Add" @click="updateTags">
            <i class="fa fa-check" style="margin: 0;" @click="updateTags" />
          </button>
          <button title="cancel">
            <i class="fa fa-times" style="margin: 0;" @click="isEditing = false; errorMessage = null;" />
          </button>
        </div>
      </div>
    </div>
    <error-popup
      v-if="errorMessage"
      errorType="alert-danger"
      :message="errorMessage"
      style="padding-top: 5%"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import CreatableSelect from 'react-select/creatable';
import ErrorPopup from '../../ErrorPopup.vue';

export default {
  name: 'Tags',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    Tooltip,
    Select: CreatableSelect,
    ErrorPopup,
    IconInformationCircle
  },
  props: {
    keyValue: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isEditing: false,
      newTags: [],
      newTagValue: '',
      processing: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      allTags: state => state.tags.allTags,
      tags(state) {
        return state[`${this.objectType}s`][`${this.objectType}Info`].tags;
      }
    })
  },
  methods: {
    async updateTags() {
      this.processing = true;
      this.errorMessage = null;

      const tagsToDelete = this.tags
        .filter(t => !this.newTags.includes(t.tag_name))
        .map(t => t.tag_name);

      const tagsToAdd = this.newTags.filter(
        t => !this.tags.find(t2 => t2.tag_name === t)
      );

      try {
        await Promise.all([
          ...tagsToDelete.map(t =>
            this.metadataPlatformClient.delete(
              `${process.env.VUE_APP_AMUNDSEN_META_URL}/${this.objectType}/${this.keyValue}/tag/${t}`
            )
          ),
          ...tagsToAdd.map(t =>
            this.metadataPlatformClient.put(
              `${process.env.VUE_APP_AMUNDSEN_META_URL}/${this.objectType}/${this.keyValue}/tag/${t}`
            )
          )
        ]);
        // Update Store
        const objectInfo = this.$store.state[`${this.objectType}s`][`${this.objectType}Info`];
        const newTags = [...this.newTags]
          .map(t => ({ tag_name: t, tag_type: 'default' }));

        this.$store.commit(`${this.objectType}s/${this.objectType}Info`, Object.assign({}, objectInfo, { tags: newTags }));
        this.isEditing = false;
      } catch (e) {
        this.errorMessage
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unknown error occurred';
      }
      this.processing = false;
    }
  }
};
</script>
<style scoped>
.autocomplete-results {
  padding: 0;
  margin: -10px 0px 0px 0px;
  border: 1px solid #eeeeee;
  max-height: 120px;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px 4px 4px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #3a97d3;
  color: white;
}
</style>
