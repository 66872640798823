<template>
  <Tooltip
    v-if="computedSourceOfCuration !== 'data_portal_api'"
    class="customlink inline"
    direction="top"
    :contents="`Source of curation: ${computedSourceOfCuration}`"
  >
    <IconBookOpen size="md" />
  </Tooltip>
</template>
<script>
import { IconBookOpen } from '@cimpress-technology/react-streamline-icons/lib';
import { Tooltip } from '@cimpress/react-components';

export default {
  name: 'SourceOfCurationTooltip',
  components: {
    IconBookOpen,
    Tooltip
  },
  props: {
    sourceOfCuration: {
      type: String,
      default: null
    }
  },
  computed: {
    computedSourceOfCuration() {
      return this.sourceOfCuration || 'default';
    }
  }
};
</script>
