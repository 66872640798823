<template>
  <div>
    <div>
      <h5 class="inline">
        Product Team
      </h5>
      <Tooltip
        contents="Individual data stewards who curate this Data object and watch the metadata updates"
        class="inline"
      >
        <IconInformationCircle size="md" weight="fill" class="text-primary" />
      </Tooltip>
      <HistoryButton
        :keyValue="keyValue"
        entity="stewards"
        heading="Product Team"
      />
      <div class="inline pull-right" style="margin-right: 0px">
        <button
          v-if="!readOnly && userManager.userData.app_metadata.canonical_id && !editMode && !isObjectReadOnly && !isEditBlockedBySource"
          class="btn-icon transparent"
          @click="editMode = true"
        >
          <Tooltip direction="top" contents="Edit">
            <i class="fa fa-pencil" />
          </Tooltip>
        </button>
      </div>
    </div>

    <!-- Editing -->
    <div
      v-if="!updatingSteward && editMode && userManager.userData.app_metadata.canonical_id"
      class="col-xs-12"
      style="padding: 0; margin: 0;"
    >
      <div
        v-if="editMode && userManager.userData.app_metadata.canonical_id"
        class="dropdown pull-left form-group"
        :class="[stewardToAdd ? 'open form-group-active ' : '', errorMessage && errorMessage != '' ? 'has-error' : '']"
        :aria-expanded="stewardToAdd"
        style="width: 80%;"
      >
        <label class="control-label" title="User Email">User Email</label>
        <input
          v-model="stewardToAdd"
          type="text"
          class="form-control"
          :onchange="(e) => {errorMessage = ''; stewardToAdd = e.target.value;}"
        />
        <small v-if="errorMessage && errorMessage != ''" class="help-block">{{ errorMessage }}</small>
        <ul v-if="stewardToAdd && !confirmAdd" class="dropdown-menu open col-xs-12" style="max-height: 200px; overflow: scroll; padding: 0; margin: 0;">
          <div v-if="!loadingMatchingUsers">
            <li
              v-for="(matchingUser, i) in filteredMatchingUsers"
              :key="i"
              class="autocomplete-result"
              style="padding: 5px; margin-top: 5px;"
              @click="
                () => {
                  stewardToAdd = matchingUser;
                  confirmAdd = true;
                }
              "
            >
              {{ matchingUser }}
            </li>
            <li v-if="!filteredMatchingUsers.length" class="autocomplete-result">
              No matching users found
            </li>
          </div>
          <img
            v-if="loadingMatchingUsers && !errorLoadingMatchingUsers"
            src="spinner.svg"
            width="25"
            alt="loading"
            class="text-centre"
          />
          <error-popup
            v-if="errorLoadingMatchingUsers"
            errorType="alert-danger"
            :message="errorLoadingMatchingUsers"
          />
        </ul>
      </div>
      <div class="inline-edit-group pull-right" style="padding: 0; margin: 0; min-width: 20%; width: 20%;">
        <div class="right-addon">
          <button title="Add" @click="addSteward(stewardToAdd);">
            <i class="fa fa-check" style="margin: 0;" />
          </button>
          <button title="cancel">
            <i class="fa fa-times" style="margin: 0;" @click="editMode = !editMode; errorMessage = null;" />
          </button>
        </div>
      </div>
    </div>

    <div v-if="updatingSteward" class="loading card-block" style="padding-left: 0;">
      <img src="spinner.svg" width="25" alt="loading" />
      Updating...
    </div>

    <!-- functionality for viewing data Steward -->
    <div v-for="owner in objectInfo.owners" :key="owner.email" style="margin-bottom: 5px">
      <div>
        <div>
          <button class="owner-btn btn btn-outline-primary">
            {{ owner.email.charAt(0).toUpperCase() }}
          </button>
          <span style="margin: 0px 4px 0px 4px;"><a :href="'mailto:'+owner.email">{{ owner.email }}</a></span>
          <SourceOfCurationTooltip :sourceOfCuration="owner.owner_curated_source" />
          <button
            v-if="editMode"
            class="btn btn-own transparent"
            style="float: right"
            @click="stewardToDelete = owner.email"
          >
            <i v-if="!updatingSteward" class="fa fa-trash" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="!owners || !owners.length">
      <span class="inline text-muted">No Stewards found</span>
    </div>

    <ConfirmBox
      v-if="stewardToDelete"
      :title="`Remove Steward`"
      :message="warningMessageDelete"
      @confirmOperation="
        () => {
          confirmDeleteOperation();
        }
      "
      @closeModal="stewardToDelete = null"
    />
    <div>
      <button
        v-if="userManager.userData.app_metadata.canonical_id && !isUserOwner && !updatingSteward"
        v-show="!isObjectReadOnly"
        class="btn label btn-primary"
        @click="
          () => {
            editMode = true;
            confirmAdd = true;
            addSteward(userManager.userData.app_metadata.canonical_id);
          }
        "
      >
        Add me as steward
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import ConfirmBox from '../../ConfirmBox';
import HistoryButton from './HistoryButton.vue';
import ErrorPopup from '../../ErrorPopup.vue';
import SourceOfCurationTooltip from '../SourceOfCurationTooltip';

export default {
  name: 'Stewards',
  inject: ['userManager', 'platformClient', 'metadataPlatformClient'],
  components: {
    ConfirmBox,
    Tooltip,
    IconInformationCircle,
    ErrorPopup,
    SourceOfCurationTooltip,
    HistoryButton
  },
  props: {
    keyValue: {
      type: String,
      default: () => ''
    },
    objectType: {
      type: String,
      required: true
    },
    isStable: {
      type: Boolean,
      default: () => false
    },
    readOnly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      editMode: false,
      confirmAdd: false,
      stewardsHistoryOn: false,

      stewardToAdd: '',
      stewardToDelete: null,

      loadingMatchingUsers: false,
      timer: null,

      errorMessage: null,
      errorLoadingMatchingUsers: null
    };
  },
  computed: {
    ...mapState({
      updatingSteward: state => state.users.updatingSteward,
      errorUpdatingSteward: state => state.users.errorUpdatingSteward,
      checkUserExists: state => state.users.checkUserExists,
      matchingUsers: state => state.users.matchingUsers
    }),
    warningMessageDelete() {
      const message = `Are you sure you want to remove <b>${this.stewardToDelete}</b> from Steward(s) list?`;
      return message;
    },
    objectInfo() {
      return this.$store.state[`${this.objectType}s`][`${this.objectType}Info`];
    },
    isObjectReadOnly() {
      return this.$store.state[`${this.objectType}s`].isObjectReadOnly;
    },
    filteredMatchingUsers() {
      return this.matchingUsers.filter(val => !this.owners.includes(val.toLowerCase()));
    },
    owners() {
      return this.objectInfo.owners.map(userData => userData.email);
    },
    ownersUniqueSources() {
      return Array.from(new Set(this.objectInfo.owners.map(userData => userData.owner_curated_source) || []));
    },
    isEditBlockedBySource() {
      return this.ownersUniqueSources.includes('data_portal_api');
    },
    isUserOwner() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return !!this.owners.find(owner => owner === userEmail);
    }
  },
  watch: {
    stewardToAdd() {
      this.errorLoadingMatchingUsers = null;
      clearTimeout(this.timer);
      if (this.stewardToAdd) {
        this.errorMessage = '';
        this.loadingMatchingUsers = true;
        this.timer = setTimeout(async () => {
          try {
            await this.getMatchingUsers({
              platformClient: this.platformClient,
              userEmail: this.stewardToAdd
            });
            this.loadingMatchingUsers = false;
          } catch (e) {
            this.errorLoadingMatchingUsers = 'Unable to fetch matching emails';
            console.log(e);
          }
        }, 400);
      }
    }
  },
  methods: {
    ...mapActions({
      deleteSteward: 'users/deleteSteward',
      updateSteward: 'users/updateSteward',
      getMatchingUsers: 'users/getMatchingUsers'
    }),
    // eslint-disable-next-line require-await
    async confirmDeleteOperation() {
      this.removeSteward(this.stewardToDelete);
      this.stewardToDelete = null;
    },
    async addSteward(steward) {
      if (!steward) {
        this.errorMessage = 'Please add proper email';
        return;
      }

      if (!this.confirmAdd) {
        this.errorMessage = 'Please select appropriate email from dropdown';
        return;
      }

      this.confirmAdd = false;
      let newOwners = [...this.objectInfo.owners];

      await this.updateSteward({
        platformClient: this.metadataPlatformClient,
        stewardToUpdate: steward,
        type: this.objectType,
        keyValue: this.keyValue
      });
      if (!this.errorUpdatingSteward) {
        newOwners.push({ email: steward.toLowerCase() });
        this.stewardToAdd = '';
      }
      this.errorMessage = this.errorUpdatingSteward;

      // Update Store
      this.$store.commit(`${this.objectType}s/${this.objectType}Info`, Object.assign({}, this.objectInfo, { owners: newOwners }));
    },

    async removeSteward(steward) {
      let newOwners = [...this.objectInfo.owners];
      this.confirmAdd = false;
      await this.deleteSteward({
        platformClient: this.metadataPlatformClient,
        stewardToDelete: steward,
        userEmail: this.userManager.userData.app_metadata.canonical_id.toLowerCase(),
        type: this.objectType,
        keyValue: this.keyValue
      });
      if (!this.errorUpdatingSteward) {
        newOwners = newOwners.filter(o => o.email.toLowerCase() !== steward.toLowerCase());
      }
      this.errorMessage = this.errorUpdatingSteward;
      // Update Store
      this.$store.commit(`${this.objectType}s/${this.objectType}Info`, Object.assign({}, this.objectInfo, { owners: newOwners }));
    }

  }
};
</script>
<style scoped>
.btn-own {
  margin: 0px;
  padding: 0px;
  color: #cb4154;
}

.btn-primary {
  font-size: 12px;
}
.autocomplete-results {
  z-index: 5;
  position: absolute;
  padding: 0;
  margin: 0;
  margin-top: -25px;
  width: 90%;
  background-color: white;
  box-shadow: 0 0 8px 0 #666;
  max-height: 120px;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px 4px 4px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #3a97d3;
  color: white;
}
.input-text > .form-group {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
