
export const getDomains = async ({ platformClient, accountId }) => {
  let response = null;
  response = await platformClient.get(`https://api.data.cimpress.io/v0/domains?isDataDomain=true${accountId ? `&accountId=${accountId}` : ''}`);
  return response.data.results;
};

export const getDomainDetails = async ({ platformClient, domainId }) => {
  const response = await platformClient.get(`https://api.data.cimpress.io/v0/domains/${domainId}`);
  return response.data;
};

/**
 * Use `searchKey = ByResourceId` to search by resourceId. In the case of PDW Datasets, the format is `ACCOUNT.DATABASE.SCHEMA.OBJECT`
 * @param {Object<{platformClient: Object, searchKey: String, searchValue: String}>} param0 - Object containing platformClient, searchKey and searchValue
 * @returns - `Object` containing dataProductId and other Data Product details or `null` if not found or any other error
 */
export const searchDataProducts = async ({ platformClient, searchKey, searchValue }) => {
  try {
    const response = await platformClient.get(`https://api.data.cimpress.io/v0/search/dataproducts?searchKey=${searchKey}&searchValue=${searchValue}`);
    return response.data;
  } catch (error) {
    console.error(`Error searching Data Products: ${error}`);
    return null;
  }
};
