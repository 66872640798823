<template>
  <div>
    <div>
      <h5 class="inline">
        Domain
      </h5>
      <Tooltip
        direction="top"
        contents="Domain to which this Data object belongs. Only Stewards are allowed to edit."
        class="inline"
      >
        <IconInformationCircle size="md" weight="fill" class="text-primary" />
      </Tooltip>
      <SourceOfCurationTooltip :sourceOfCuration="domainIdSource" />
      <button
        v-if="isEditVisible"
        class="btn-icon transparent"
        @click="isEditing = true"
      >
        <Tooltip direction="top" contents="Edit">
          <i class="fa fa-pencil" />
        </Tooltip>
      </button>
    </div>
    <div v-if="loadingDomains" class="loading inline">
      <img src="spinner.svg" width="25" alt="loading" />
      Loading domains...
    </div>

    <div v-else-if="errorLoadingDomains" class="loading inline">
      Error loading domains
      <Button size="sm" :disabled="processing" @onClick="loadDomains">
        Retry
      </Button>
      <Button v-if="isEditVisible" size="sm" :disabled="processing" @onClick="unsetDomainAndReload">
        Unset Domain
      </Button>
      {{ errorLoadingDomains }}
    </div>

    <div v-else-if="!isEditing">
      <div v-if="!domainId">
        <span
          v-if="!disabled && !this.isObjectReadOnly"
          class="inline text-primary"
          style="cursor: pointer"
          @click="isEditing = true"
        >
          + Add Domain
        </span>
        <span v-else class="text-muted">No Domain added </span>
      </div>
      <div v-else style="margin: 2px; font-size: 14px;" class="tag tag_modified">
        {{ markedDomainName }}
      </div>
    </div>

    <!-- Editing -->
    <div v-else>
      <div>
        <Select
          v-if="!processing"
          label="Select a domain"
          :status="errorMessage ? 'error': ''"
          class="pull-left"
          style="width: 90%;"
          :helpText="errorMessage || ''"
          :isClearable="true"
          :isDisabled="processing"
          :options="domainOptions"
          :value="domainOptions.find((d) => d.value === domainId)"
          @onChange="(e) => updateDomain(e ? e : '')"
        />
        <div v-if="!processing" class="inline-edit-group pull-right" style="padding: 0; margin: 0; min-width: 10%; width: 10%;">
          <div class="right-addon">
            <button title="cancel">
              <i class="fa fa-times" style="margin: 0;" @click="isEditing = false; errorMessage = null;" />
            </button>
          </div>
        </div>
        <div class="row">
          <div v-if="processing" class="col-md-8">
            <div class="loading inline">
              <img src="spinner.svg" width="25" alt="loading" />
              Updating...
            </div>
          </div>
        </div>
        <!-- Error Handling -->
        <error-popup
          v-if="errorMessage"
          errorType="alert-danger"
          :message="errorMessage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { Tooltip, Button, Select } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import { putProgramaticDescription } from '../../../clients/dataDiscovery';
import { getDomainDetails, getDomains } from '../../../clients/dataPortal';
import ErrorPopup from '../../ErrorPopup.vue';
import { clusterToAccountId } from './../../../utils/accountIdMapping';
import SourceOfCurationTooltip from '../SourceOfCurationTooltip';

export default {
  name: 'Domain',
  inject: ['userManager', 'platformClient', 'metadataPlatformClient'],
  components: {
    Select,
    Button,
    IconInformationCircle,
    Tooltip,
    ErrorPopup,
    SourceOfCurationTooltip
  },
  props: {
    objectKey: {
      type: String,
      required: true
    },
    objectType: {
      type: String,
      required: true
    },
    domainId: {
      type: String,
      default: () => null
    },
    disabled: {
      type: Boolean,
      required: true
    },
    cluster: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingDomains: false,
      errorLoadingDomains: false,
      isEditing: false,
      processing: false,
      errorMessage: null,
      domainOptions: [],
      markedDomainName: ''
    };
  },
  computed: {
    ...mapState({
      isObjectReadOnly: state => state.tables.isObjectReadOnly,
      domainIdSource: state => ((state.tables.tableInfo.programmatic_descriptions || []).find(p => p.source === 'domain_id_source') || {}).text
    }),
    isEditVisible() {
      return !this.isEditing && !this.disabled && !this.loadingDomains && !this.isObjectReadOnly && this.domainIdSource !== 'data_portal_api';
    }
  },
  created() {
    this.loadDomains();
  },
  methods: {
    async loadDomains() {
      this.loadingDomains = true;
      this.errorLoadingDomains = null;
      this.domainOptions = [];

      try {
        if (this.domainId) {
          this.markedDomainName = (await getDomainDetails({
            platformClient: this.platformClient,
            domainId: this.domainId
          })).name;
        }
        this.domainOptions = (await getDomains({
          platformClient: this.platformClient,
          accountId: clusterToAccountId[this.cluster]
        })).map(d => ({ label: d.name, value: d.domainId }));
      } catch (error) {
        this.errorLoadingDomains = error;
      }

      this.loadingDomains = false;
    },
    async unsetDomainAndReload() {
      await this.updateDomain({ value: '' });
      this.loadDomains();
    },
    async updateDomain(newDomain) {
      if (this.domainId !== newDomain.value) {
        this.errorMessage = null;
        this.processing = true;
        try {
          await putProgramaticDescription({
            platformClient: this.metadataPlatformClient,
            objectType: this.objectType,
            objectId: this.objectKey,
            key: 'domain_id',
            value: newDomain.value || ''
          });
          // Commit change to the store
          const objectInfo
          = this.$store.state[`${this.objectType}s`][`${this.objectType}Info`];
          const newPDesc = [...(objectInfo.programmatic_descriptions || [])].filter(
            b => b.source !== 'domain_id'
          );

          if (newDomain) {
            newPDesc.push({ text: newDomain.value, source: 'domain_id' });
          }
          this.$store.commit(
            `${this.objectType}s/${this.objectType}Info`,
            Object.assign({}, objectInfo, { programmatic_descriptions: newPDesc })
          );

          this.markedDomainName = newDomain.label;
          this.isEditing = false;
        } catch (e) {
          this.errorMessage
          = e.message
          || e.data.message
          || e.data.title
          || (e.status && `Error occurred with status code ${e.status}`)
          || 'Unexpected error occurred.';
        }
        this.processing = false;
      } else {
        this.isEditing = false;
      }
    }
  }
};
</script>
<style>
.tag_modified {
  background-color: #0088a9;
  color: #ffffff;
}
</style>
