export const CREATING_STABLE_DATASETS_URL = 'https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/1257179058/Creating+Stable+Data+Sets';
export const ATTRS_OF_STABLE_DATASET_URL = 'https://cimpress-support.atlassian.net/wiki/spaces/DPT/pages/1257179058/Attributes+of+a+Stable+Data+Set';

function environmentToDataPortalSubDomain(environment) {
  switch (environment) {
    case 'prod':
      return 'data';
    case 'staging':
      return 'stg.data';
    default:
      return 'data';
  }
}

export function getDataProductConsumerViewURL(dataProductId, environment = 'prod') {
  const subDomain = environmentToDataPortalSubDomain(environment);
  return `https://${subDomain}.cimpress.io/discover/${dataProductId}`;
}

export function getDataProductProducerViewURL(dataProductId, environment = 'prod') {
  const subDomain = environmentToDataPortalSubDomain(environment);
  return `https://${subDomain}.cimpress.io/dataproducts/${dataProductId}`;
}
