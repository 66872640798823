<template>
  <div>
    <h5 class="inline" style="color: grey">
      Status
    </h5>
    <Tooltip
      direction="top"
      contents="Click to know more about stable Datasets"
      class="inline"
    >
      <a
        :href="stableDatasetDefinitionURL"
        target="_blank"
      >
        <IconInformationCircle size="md" weight="fill" />
      </a>
    </Tooltip>
    <SourceOfCurationTooltip
      :sourceOfCuration="tableStatusSource"
    />
    <HistoryButton :keyValue="keyValue" entity="table_status" heading="Table Status" />
    <div v-if="!loadingTableStatus">
      <Tooltip
        :contents="`Status of this ${objectType}. Only stewards are allowed to edit.`"
        class="inline"
      >
        <div v-click-outside="closeTableStatusDropdown">
          <span
            class="dropdown-toggle customlink"
            @click="tableStatusClick = !tableStatusClick"
          >
            <!-- TODO: Create Reusable Status -->
            <div style="display: flex; flex-direction: row; align-items: center; gap: 8px;">
              <div :style="`height:8px; width: 8px; border: 1px solid ${statusColor} ; background-color: ${statusColor} ; border-radius: 50%;`"></div>
              <span style="color:grey">{{ capitalizedTableStatus }}</span>
              <span
                v-if="isDropDownVisible"
                class="caret"
              />
            </div>
          </span>
        </div>
      </Tooltip>
      <div v-if="isDropDownVisible">
        <div
          class="dropdown"
          :class="tableStatusClick ? 'open' : 'close'"
        >
          <ul class="dropdown-menu" style="align-items: center">
            <li align="center">
              <a
                style="font-weight: bold; color: green"
                @click="updateTableStatus('stable')"
              >Stable</a>
            </li>
            <li align="center">
              <a
                style="font-weight: bold; color: #ffa237"
                @click="updateTableStatus('investigable')"
              >Investigable</a>
            </li>
            <li align="center">
              <a
                style="font-weight: bold; color: #ff0000"
                @click="updateTableStatus('internal')"
              >Internal</a>
            </li>
            <li align="center">
              <a
                style="font-style: italic; color: grey"
                @click="updateTableStatus(null)"
              >Not Set</a>
            </li>
          </ul>
        </div>
      </div>
      <error-popup
        v-if="errorMessage"
        errorType="snackbar-danger"
        :message="errorMessage"
      />
    </div>
    <div
      v-else-if="!errorMessage"
      class="loading"
      style="padding-top: 4px"
    >
      <img src="spinner.svg" width="25" alt="loading" />
      Loading status...
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Tooltip } from '@cimpress/react-components';
import { IconInformationCircle } from '@cimpress-technology/react-streamline-icons/lib';
import vClickOutside from 'v-click-outside';
import SourceOfCurationTooltip from '../../shared/SourceOfCurationTooltip.vue';
import ErrorPopup from '../../ErrorPopup.vue';
import HistoryButton from './HistoryButton.vue';
import { CREATING_STABLE_DATASETS_URL } from '../../../utils/linkCatalog';

export default {
  name: 'Status',
  inject: ['metadataPlatformClient', 'userManager'],
  components: {
    SourceOfCurationTooltip,
    Tooltip,
    IconInformationCircle,
    ErrorPopup,
    HistoryButton
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    keyValue: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tableStatusClick: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      table: state => state.tables.tableInfo,
      loadingTableStatus: state => state.tables.loadingTableStatus,
      isObjectReadOnly: state => state.tables.isObjectReadOnly,
      tableStatusSource: state => (state.tables.tableInfo.programmatic_descriptions.find(p => p.source === 'table_status_source') || {}).text,
      tableStatus: state => (state.tables.tableInfo.programmatic_descriptions.find(p => p.source === 'table_status') || {}).text || 'Not Set'
    }),
    isUserSteward() {
      const userEmail = this.userManager.userData.app_metadata.canonical_id.toLowerCase();
      return this.table.owners.some(owner => owner.email.toLowerCase() === userEmail);
    },
    isDropDownVisible() {
      return this.isUserSteward && !this.isObjectReadOnly && this.tableStatusSource !== 'data_portal_api';
    },
    objectType() {
      return this.table.is_view ? 'View' : 'Table';
    },
    statusColor() {
      switch (this.tableStatus) {
        case 'stable':
          return 'green';
        case 'investigable':
          return '#ffa237';
        case 'internal':
          return '#ff0000';
        case 'not_set':
          return 'grey';
        default:
          return 'grey';
      }
    },
    capitalizedTableStatus() {
      if (!this.tableStatus) { return ''; }
      return this.tableStatus.charAt(0).toUpperCase() + this.tableStatus.slice(1);
    },
    stableDatasetDefinitionURL() {
      return CREATING_STABLE_DATASETS_URL;
    }
  },
  methods: {
    ...mapActions({
      putTableStatus: 'tables/putTableStatus'
    }),
    closeTableStatusDropdown() {
      this.tableStatusClick = false;
    },
    async updateTableStatus(status) {
      this.errorMessage = null;
      this.tableStatusClick = !this.tableStatusClick;
      try {
        await this.putTableStatus({
          platformClient: this.metadataPlatformClient,
          keyValue: this.keyValue,
          tableStatus: status
        });
      } catch (err) {
        this.errorMessage
          = err.message
          || err.data.message
          || err.data.title
          || (err.status && `Error occurred with status code ${err.status}`)
          || 'Unknown error occurred';
        console.log(err);
      }
    }
  }
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
