<template>
  <div style="margin: 25px">
    <div v-if="loadingSchemaInfo" align="center">
      <img src="spinner.svg" alt="loading" />
      <br /><br />
      <span>Loading schema info...</span>
      <br /><br />
    </div>
    <error-popup
      v-else-if="schemaInfoError"
      errorType="alert-danger-dismissible"
      :html="schemaInfoError"
    />
    <div v-else>
      <SchemaHeader :keyValue="schemaKeyDecoded" />
      <div>
        <ul class="nav nav-tabs" style="cursor: pointer">
          <li
            :class="{ active: activeTab === 'overview' }"
            @click="pushPathParameters('overview')"
          >
            <a style="margin-left: 0px"> Overview </a>
          </li>
          <li
            :class="{ active: activeTab === 'comments' }"
            @click="pushPathParameters('comments')"
          >
            <CommentsTabHeader :itemKey="itemKey" objectType="schema" />
          </li>
        </ul>
        <hr />
      </div>

      <div v-if="activeTab === 'overview'">
        <OverviewSection />
      </div>
      <div v-if="activeTab === 'comments'">
        <CommentsTab :itemKey="itemKey" objectType="schema" :keyValue="schemaKeyDecoded" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CommentsTabHeader from '../shared/CommentsTabHeader.vue';
import CommentsTab from '../shared/CommentsTab.vue';
import SchemaHeader from './SchemaHeader';
import OverviewSection from './OverviewSection';
import ErrorPopup from '../ErrorPopup';

export default {
  name: 'Schema',
  inject: ['userManager', 'metadataPlatformClient'],
  components: {
    SchemaHeader,
    OverviewSection,
    ErrorPopup,
    CommentsTabHeader,
    CommentsTab
  },
  props: {
    itemKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'overview',
      schemaKeyDecoded: ''
    };
  },

  computed: {
    ...mapState({
      schemaInfo: state => state.schemas.schemaInfo,
      loadingSchemaInfo: state => state.schemas.loadingSchemaInfo,
      schemaInfoError: state => state.schemas.schemaInfoError
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query && this.$route.query.tab) {
          this.activeTab = this.$route.query.tab;
        } else {
          this.activeTab = 'overview';
        }
      }
    },
    itemKey: {
      immediate: true,
      handler() {
        if (!this.itemKey) {
          return;
        }

        const parts = this.itemKey.split('.');
        const cluster = `${parts[0]}.${parts[1]}`;
        const database = parts[2];
        const schema = parts[3];

        this.schemaKey = `${database}://${cluster}.${schema}`;
        this.schemaKeyDecoded = encodeURIComponent(this.schemaKey);

        // Not awaited
        this.getSchemaInfo({
          platformClient: this.metadataPlatformClient,
          keyValue: this.schemaKeyDecoded,
          userEmail: this.userManager.userData.app_metadata.canonical_id.toLowerCase()
        });
      }
    }
  },
  methods: {
    ...mapActions({
      getSchemaInfo: 'schemas/getSchemaInfo'
    }),
    pushPathParameters(activeTab) {
      this.$router.push({
        query: Object.assign({}, this.$route.query || {}, { tab: activeTab })
      });
    }
  }
};
</script>
