var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-8"},[_c('h5',[_vm._v("Comments")]),_c('Comments',{attrs:{"resourceUri":("https://api.data-discovery.cimpress.io/v0/data-sources/snowflake/data-objects/" + (_vm.dataObjectTypeMapper[_vm.objectType]) + "/" + _vm.objectKey),"newestFirst":true,"editComments":true,"deleteComments":true,"autoFocus":false,"showVisibilityLevels":false,"enforceVisibilityLevel":'public',"accessToken":_vm.platformClient.tokenResolverFunction(),"emailing":{
        enabled: !!(_vm.owners && _vm.owners.length),
        newCommentsTemplateId: '15993ff7-c758-4b7b-a385-38ec4b7f1f2e',
        newCommentsTemplatePayload: {
          to: _vm.owners.join('; '),
          objectType: _vm.dataObjectTypeMapper[_vm.objectType],
          itemKey: _vm.objectKey,
          ddLink: ("https://data-discovery.cimpress.io/#/?selectedTreeItem=" + _vm.itemKey + "&tab=comments")
        }
      }}})],1),_c('div',{staticClass:"col-sm-4"},[_c('Stewards',{staticClass:"card",staticStyle:{"padding":"15px","padding-bottom":"24px"},attrs:{"keyValue":_vm.keyValue,"objectType":_vm.objectType,"readOnly":true}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }