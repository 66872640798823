<template>
  <div>
    <div v-if="dataProduct && isUserSteward" class="pull-right">
      <Button size="sm" variant="primary" @onClick="openConsumerViewInNewTab">
        Edit in Data Portal
      </Button>
    </div>
    <h5>Assigned to Data Product</h5>
    <div v-if="loadingDataProducts" class="loading inline">
      <img src="spinner.svg" width="25" alt="loading" />
      Searching Data Product...
    </div>
    <div v-else-if="errorLoadingDataProducts" class="loading inline">
      Error Searching Data Product
      <Button size="sm" @onClick="searchDataProduct">
        Retry
      </Button>
    </div>
    <div v-else>
      <div v-if="dataProduct">
        <a :href="dataProductConsumerViewURL" target="_blank" rel="noopener noreferrer">
          {{ dataProduct.dataProductName }} <i class="fa fa-external-link" />
        </a>
      </div>
      <div v-else>
        <span class="text-muted">Not assigned to a Data Product</span>
      </div>
    </div>
  </div>
</template>
<script>
import { searchDataProducts } from '../../../clients/dataPortal';
import { Button } from '@cimpress/react-components';
import { getDataProductConsumerViewURL, getDataProductProducerViewURL } from '../../../utils/linkCatalog';

export default {
  name: 'DataProductLink',
  inject: ['platformClient'],
  components: {
    Button
  },
  props: {
    isDataPortalCurated: {
      type: Boolean,
      required: true
    },
    tableKeyFormatted: {
      type: String,
      required: true
    },
    isUserSteward: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dataProduct: null,
      loadingDataProducts: false,
      errorLoadingDataProducts: false
    };
  },
  computed: {
    dataProductProducerViewURL() {
      if (!this.dataProduct) {
        return '#';
      }
      return getDataProductProducerViewURL(this.dataProduct.dataProductId);
    },
    dataProductConsumerViewURL() {
      if (!this.dataProduct) {
        return '#';
      }
      return getDataProductConsumerViewURL(this.dataProduct.dataProductId);
    }
  },
  created() {
    this.searchDataProduct();
  },
  methods: {
    async searchDataProduct() {
      try {
        if (!this.isDataPortalCurated) {
          return;
        }
        this.loadingDataProducts = true;
        this.errorLoadingDataProducts = false;
        this.dataProduct = await searchDataProducts({
          platformClient: this.platformClient,
          searchKey: 'ByResourceId',
          searchValue: this.tableKeyFormatted
        });
      } catch (error) {
        this.errorLoadingDataProducts = true;
      } finally {
        this.loadingDataProducts = false;
      }
    },
    openConsumerViewInNewTab() {
      window.open(this.dataProductProducerViewURL, '_blank', 'noopener,noreferrer');
    }
  }
};
</script>
