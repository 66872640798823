<template>
  <div>
    <div>
      <ul class="nav nav-tabs" style="cursor: pointer; margin-bottom: 0;">
        <li
          :class="{ active: Active === 'overview' }"
          @click="pushPathParameters('overview')"
        >
          <a style="margin-left: 0px"> Overview </a>
        </li>
        <li
          :class="{ active: Active === 'columns' }"
          @click="pushPathParameters('columns')"
        >
          <a>
            Columns <span style="color: #aaa">({{ columnCount }})</span>
          </a>
        </li>
        <li
          :class="{ active: Active === 'queries' }"
          @click="pushPathParameters('queries')"
        >
          <a> Query Usage </a>
        </li>
        <li
          :class="{ active: Active === 'lineage' }"
          @click="pushPathParameters('lineage')"
        >
          <a> Lineage </a>
        </li>
        <li
          :class="{ active: Active === 'grapher' }"
          @click="pushPathParameters('grapher')"
        >
          <a> Looker Dependencies </a>
        </li>
        <li
          :class="{ active: Active === 'comments' }"
          @click="pushPathParameters('comments')"
        >
          <CommentsTabHeader :itemKey="itemKey" objectType="table" />
        </li>
      </ul>
    </div>
    <hr />

    <div class="card-block" :class="Active !== 'overview' ? 'hidden-tab': ''">
      <OverviewTab />
    </div>

    <div :class="Active !== 'columns' ? 'hidden-tab': ''">
      <table-columns />
    </div>

    <div class="card-block" :class="Active !== 'queries' ? 'hidden-tab': ''">
      <LookerDashboard
        :itemKey="itemKey"
        objectType="queries"
        configurationId="9c458656-d338-445a-8ddf-6fd59b92ddc0"
      />
    </div>

    <div class="card-block" :class="Active !== 'lineage' ? 'hidden-tab': ''">
      <LookerDashboard
        :itemKey="itemKey"
        objectType="lineage"
        configurationId="6aebeca5-2226-4ef4-9214-6d0f04bf6c0e"
      />
    </div>
    <div :class="Active !== 'grapher' ? 'hidden-tab': ''">
      <LookerGrapherTab />
    </div>

    <div :class="Active !== 'comments' ? 'hidden-tab': ''">
      <CommentsTab :itemKey="itemKey" objectType="table" :keyValue="keyValue" />
    </div>
  </div>
</template>

<script>
import TableColumns from './TableColumns';
import OverviewTab from './OverviewTab';
import LookerGrapherTab from './LookerGrapherTab';
import LookerDashboard from '../shared/LookerDashboard.vue';
import CommentsTabHeader from '../shared/CommentsTabHeader.vue';
import CommentsTab from '../shared/CommentsTab.vue';

export default {
  name: 'TableTabs',
  components: {
    TableColumns,
    OverviewTab,
    LookerDashboard,
    LookerGrapherTab,
    CommentsTabHeader,
    CommentsTab
  },
  props: {
    itemKey: {
      type: String,
      required: true
    },
    columnCount: {
      type: Number,
      default: 0
    },
    keyValue: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      Active: 'overview'
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query && this.$route.query.tab) {
          this.Active = this.$route.query.tab;
        } else {
          this.Active = 'overview';
        }
      }
    }
  },
  methods: {
    pushPathParameters(Active) {
      this.$router.push({
        query: Object.assign({}, this.$route.query || {}, { tab: Active })
      });
    }
  }
};
</script>
<style scoped>
.hidden-tab {
  visibility: hidden;
  display: none;
  height: 0vh;
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
